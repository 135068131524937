@mixin phone {
  @media (max-width: #{$tabletWidth - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tabletWidth}) and (max-width: #{$desktopWidth - 1px}) {
    @content;
  }
}

@mixin tabletMin {
  @media (min-width: #{$tabletWidth}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktopWidth}) {
    @content;
  }
}
.feature-grid {
  background-color: $lightGrey;
  padding-top: 40px;
  padding-bottom: 40px;

  @include tabletMin {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  &__headlines {
    margin-bottom: 20px;
    padding: 0 20px;

    @include tabletMin {
      margin-bottom: 40px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__feature {
    width: 100%;
    padding: 20px;

    @include tablet {
      width: 50%;
    }

    @include tabletMin {
      padding: 35px;
    }

    @include desktop {
      width: 25%;
    }

    &--big {
      width: 100%;
      padding: 20px 0;
      background-color: $white;
      display: flex;
      flex-wrap: wrap;

      @include tabletMin {
        padding: 25px 0;
      }

      @include desktop {
        width: 50%;
      }

      .feature-grid__feature {
        width: 100%;
        padding: 10px 20px;

        @include tabletMin {
          width: 50%;
          padding: 10px 35px;
        }
      }
    }

    &-hint {
      margin: 10px 20px 0 20px;
      border-top: 1px solid $black;
      padding-top: 20px;
      font-size: 14px;
      line-height: 120%;

      @include tabletMin {
        margin: 10px 35px 0 35px;
      }

      span {
        color: $hpBlue;
      }
    }

    &-content {
      margin-top: 25px;
    }

    &-image {
      img {
        width: 100%;
      }
    }
  }
}
.pca {
  margin: 20px;

  @include tabletMin {
    margin: 0 20px 45px 20px;
  }

  &__wrapper {
    @include tabletMin {
      display: flex;
    }
  }

  &__main-image {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tabletMin {
      width: 60%;
    }

    img {
      max-width: 100%;
    }
  }

  &__list-wrapper {
    padding-top: 20px;

    @include tabletMin {
      width: 40%;
      padding: 0 40px;
      align-self: center;
    }
  }

  &__list {
    list-style: none;
    margin-top: 30px;
  }

  &__link {
    padding: 10px 0;
    cursor: pointer;
    position: relative;
    transition: all 500ms;

    &.active {
      .click-area {
        color: $white;
        background-color: $black;
        border-radius: 5px;
        padding: 10px;
        cursor: default;
      }
    }
  }

  &__content-wrapper {
    overflow: hidden;
  }

  &__content {
    max-height: 0;
    margin-top: 0;
    transition: all 300ms ease-out;
  }

  .active {
    .pca__content {
      max-height: 600px;
      margin-top: 1rem;
      transition: max-height 500ms ease-in;
    }
  }
}
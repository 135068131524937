.bca {
  &__top {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    min-width: 0;

    &-wrapper {
      justify-content: center;
      align-items: center;
      text-align: center;
      min-width: 0;

      @include phone {
        display: block;
        background-image: none !important;
        min-height: auto;
        background-color: white;
      }

      @include tabletMin {
        min-height: 550px;
        background-color: #e8e8e8;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 500px;
        display: flex;
      }

      @include tablet {
        background-image: url('../../public/hp-static/uploads/images/wolf-tab2-hero-d.jpg');
      }

      @include desktop {
        background-image: url('../../public/hp-static/uploads/images/wolf-tab2-hero-d.jpg');
      }
    }

    &-mobile-img {
      @include phone {
        display: block;
        width: 100%;
      }

      @include tabletMin {
        display: none;
      }
    }

    &-content {
      text-align: right;

      @include phone {
        padding: 20px;
      }

      &-box {
        @include tabletMin {
          width: 55%;
          padding: 40px;
          background: $white;
          text-align: left;
        }

        @include desktop {
          width: 45%;
          margin-left: 55%;
        }
      }
    }
  }

  &__bottom {
    padding: 20px;
    margin: 0;
    background-color: #191919;

    @include desktop {
      padding: 45px 20px 180px 20px;

      &-wrapper {
        display: flex;
      }

      &-image {
        order: 2;
        width: 70%;
      }

      &-content {
        order: 1;
        width: 30%;
        align-self: center;
      }
    }

    &-image {
      img {
        width: 100%;
        height: auto;
      }
    }

    &-content {
      padding: 20px;

      @include desktop {
        padding: 0 40px;
      }
    }

    &-cards {
      list-style: none;
      margin-top: 30px;

      @include desktop {
        margin-top: 0;
        position: absolute;
        display: flex;
        min-height: 280px;
        max-height: 350px;
        padding-left: 15%;
      }
    }

    &-card {
      color: white;
      border-radius: 10px;
      background-color: #6d6e71;
      padding: 20px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      @include desktop {
        width: 33.33%;
        position: relative;
        margin: 5px !important;
        box-shadow: 8px 12px 54px -21px $black;
      }

      .title-h5 {
        padding-bottom: 10px;
      }
    }
  }

  div.modal {
    display: none;
  }
}
.hero {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	&__content {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		min-height: 500px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		background-color: $lightGrey;
		background-image: url("../../public/hp-static/uploads/images/wolf-hero-t.jpg");

		@include tabletMin {
			min-height: 550px;
		}

		@include desktop {
			background-image: url("../../public/hp-static/uploads/images/wolf-hero-d.jpg");
		}
	}

	.hide_desktop {
		@include tabletMin {
			display: none;
		}
	}

	&__content-inner {
		width: 100%;
		display: flex;
		flex-direction: column;

		/* fix for buttons truncated text on mobile */
		overflow: hidden;
		z-index: 1;

		&--video {
			padding: 20px;

			@include tablet {
				padding: 45px 55px;
			}

			@include tabletMin {
				margin: 50px 0;

				.hero__text {
					padding: 45px 40px;
				}
			}

			@include desktop {
				padding-top: 40px;
				padding: 20px 70px;
			}
		}

		@include tablet {
			min-height: 400px;

			.hero__text {
				width: 60%;
				padding: 60px 40px 45px 30px;
			}
		}

		@include tabletMin {
			flex-direction: row;

			.hero__image {
				width: 60%;
			}

			.hero__text {
				align-self: center;
				max-width: 654px;
				margin-left: auto;
				margin-right: auto;
			}

			&.image-is-right {
				.hero__image {
					order: 2;
				}

				.hero__text {
					order: 1;
				}
			}
		}

		@include desktop {
			min-height: 600px;

			.hero__text {
				width: 50%;
				padding: 70px 40px 45px 110px;
			}
		}

		.hero__image {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			position: relative;

			@include tabletMin {
				height: auto;
				height: 650px;
			}

			.form__wrapper-desktop {
				width: 100%;
				display: none;
				@include phone {
					display: none;
				}
				@include tabletMin {
					height: 650px;
					display: block;
				}
			}
			&--bg-img {
				//background-image: url("../../public/images/hero/hp-wolf-header.png");
				background-position: center;

				@include phone {
					margin-bottom: 20px;
				}
			}

			.video {
				display: none;

				@include tabletMin {
					display: block;
					background: $white;
					position: absolute;
					width: 100%;
					min-height: 100%;
					left: 0;
					top: 0;
					overflow: hidden;
					z-index: 0;

					video {
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						-webkit-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
					}
				}
			}

			.video-thumb {
				display: inline-block;
				height: 70px;
				width: 70px;
				position: absolute;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					background: url(../../public/hp-static/uploads/images/icon-play-video.svg)
						center no-repeat;
					position: absolute;
					top: 0;
					transition: opacity 0.3s ease;
					opacity: 0.8;
				}

				&:hover::after {
					opacity: 1;
				}
			}
		}
	}
	.form__wrapper-mobile {
		width: 100%;
		display: block;
		height: 650px;
		position: relative;
		z-index: 2500;
		@include tabletMin {
			display: none;
		}
	}
	&__text {
		padding: 20px;
	}
}

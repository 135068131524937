.modal {
  &-wrapper {
    position: absolute;
    width: 100%;
    max-width: 850px;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    z-index: 2000;
  }

  &-overlay {
    background: rgba(0, 0, 0, 0.8);
    display: none;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &-body {
    position: relative;
    padding: 50px 20px 0;
  }

  .contact-container,
  .video-container {
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.01875rem;
  }

  .contact-container {
    background-color: $white;
    padding: 30px;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    display: block;
    width: 34px;
    height: 34px;
    border: 0;
    cursor: pointer;
    outline: none;

    &:hover {
      &::before, &::after {
        background-color: $hpBlue;
      }
    }

    &::before, &::after {
      content: '';
      position: absolute;
      top: 16px;
      left: 0;
      display: block;
      width: 34px;
      height: 1px;
      background-color: $white;
      -webkit-transition: background-color .3s;
      transition: background-color .3s;
      opacity: 1;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
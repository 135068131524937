.button {
  transition: color 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;
  display: inline-block;
  padding: 0 32px;
  text-indent: 0.1rem;
  cursor: pointer;
  outline: none;
  font-family: $fontRegular;
  line-height: 2.375rem;
  text-transform: uppercase;
  margin: 1rem 0.5rem 0;
  color: $white;
  background-color: $hpBlue;
  border: 1px solid $hpBlue;

  &:hover {
    text-decoration: none;
  }

  .text-small {
    padding:0;
    margin: 0;
    font-size: 0.75rem;
    margin-left:20px;
  }

  &-text-small {
    font-size:0.75rem;
  }

  &.primary {
    color: $white;
    background-color: $hpBlue;
    border: 1px solid $hpBlue;

    &:hover, &:focus {
      background-color: $hpBlueDark;
      border-color: $hpBlueDark;
    }
  }

  &.white {
    color: $black;
    background-color: $white;
    border: 1px solid $white;

    &:hover, &:focus {
      background-color: $lightGrey;
      border-color: $lightGrey;
    }
  }

  &.dark {
    color: $white;
    background-color: $buttonDarkBG;
    border: 1px solid $buttonDarkBG;

    &:hover {
      color: $white;
      background-color: $buttonDarkBGHover;
      border-color: $buttonDarkBGHover;
    }
  }

  &.rounded {
    border-radius: 4px;
  }

  &.disabled {
    color: $buttonDisabledColor;
    background-color: $buttonDisabledBG;
    border: 1px solid $buttonDisabledBG;
    cursor: default;
  }
}
.pft {
  padding: 20px;
  background-color: $black;

  @include tablet {
    padding: 50px 55px;
  }

  @include desktop {
    padding: 50px 70px;
  }

  &__content-wrapper {
    @include tabletMin {
      max-width: 50% !important;
    }
  }

  &__content {
    text-align: center;
    text-transform: uppercase;
  }
}
.title-style-xl,
.title-h1,
.title-h2,
.title-h3,
.title-h4,
.title-h5,
.title-h6 {
  font-family: $fontRegular;
  font-weight: normal;
  letter-spacing: 0rem;
  text-transform: uppercase;
}

.title-style-xl {
  font-size: 1.875rem;
  line-height: 2.125rem;
}

.title-h1 {
  font-size: 1.625rem;
  line-height: 1.75rem;
  margin: 0;
}

.title-h2 {
  font-size: 1.2rem;
  line-height: 1.65rem;
}

.title-h3 {
  font-size: 1.375rem;
  line-height: 1.625rem;
}

.title-h4 {
  font-size: 1.25rem;
  line-height: 1.625rem;
}

.title-h4 + * {
  margin-top: .8125rem;
}

.title-h4:not(:first-child) {
  margin-top: .8125rem;
}

.title-h5 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.title-h6 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}
.subtitle {
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.01875rem;
}

.description,
.subtitle {
  margin-top: .75rem;
}

@include tablet {
  .title-style-xl {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }

  .title-h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .title-h2 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .title-h3 {
    font-size: 1.625rem;
    line-height: 1.875rem;
  }

  .title-h6 {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}

@include desktop {
  .title-style-xl {
    font-size: 3.125rem;
    line-height: 3.25rem;
  }

  .title-h1 {
    font-size: 2.5rem;
    line-height: 2.625rem;
  }

  .title-h2 {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }

  .title-h3 {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .title-h4 {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  .title-h4 + * {
    margin-top: 0.875rem;
  }

  .title-h5 {
    line-height: 1.75rem;
  }

  .subtitle {
    line-height: 1.625rem;
  }

  .description {
    margin-top: 1rem;
  }
}
.cta-wrapper {
  margin-top: .5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @include tablet {
    margin-top: 1rem;
  }
}

.cta-container {
  display: flex;
  flex-direction: column;
}
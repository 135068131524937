.popup {
	position: fixed;
	width: 100%;
	background: rgba(0, 0, 0, 0.9);
	height: 100vh;
	padding: 10px;
	z-index: 400;
	display: none;

	&.active {
		display: block;
	}

	&_content {
		display: grid;
		padding-top: 40px;
		justify-items: center;
		height: 100vh;
	}

	.nav_btn_wrapper {
		padding: 10px;

		&.close {
			margin-top: 10px;
			transform: translateX(90%);

			.navrow {
				width: 30px;
				height: 3px;
				background: white;
				margin-top: 5px;
				border-radius: 5px;

				&:first-of-type {
					margin-top: -5px;
					transform: rotate(45deg);
				}

				&:last-of-type {
					margin-top: -3px;
					transform: rotate(-45deg);
				}
			}
		}

		.navrow {
			width: 30px;
			height: 3px;
			background: white;
			margin-top: 5px;
			border-radius: 5px;

			&:first-of-type {
				margin-top: -5px;
			}

			&:last-of-type {
				margin-top: 5px;
			}
		}
	}
}

.footer {
  background: #e8e8e8;
  padding: 20px;

  @include tablet {
    padding: 20px 55px;
  }

  @include desktop {
    padding: 20px 70px;
  }

  &__links {
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;

      @include desktop {
        flex-direction: row;
        align-items: flex-start;
      }

      li {
        display: flex;
        flex: 1;
        width: 100%;

        a {
          display: block;
          width: 100%;
          text-align: center;
          padding: 1em 0;
        }

        @include desktop {
          display: block;
          width: auto;
          flex: 0;

          a {
            padding: 0;
          }

          &:not(:first-child) {
            margin-left: 1em;
          }
        }
      }
    }
  }

  &__legal {
    p {
      margin: 0.625rem 0;
      font-size: 0.75rem;
      line-height: 1rem;
    }

    &-toggler {
      cursor: pointer;
      display: inline-block;
      margin: 10px 0;
      padding-right: 12px;
      position: relative;
      font-size: 12px;

      &:after,
      &:before {
        width: 6px;
        height: 1px;
        background-color: $black;
        content: '';
        position: absolute;
        top: 50%;
        transition: transform 500ms;
        transition-delay: 200ms;
      }

      &:after {
        transform: rotate(45deg);
        right: 4px;
      }

      &:before {
        transform: rotate(-45deg);
        right: 0;
      }

      &.open {
        &:after {
          transform: rotate(-45deg);
        }

        &:before {
          transform: rotate(45deg);
        }
      }
    }

    &-toggle-content {
      max-height: 0;
      transition: max-height 500ms;
      overflow: hidden;

      &.open {
        max-height: 100vh;
      }
    }
  }
}
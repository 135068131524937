// Colors
$white: #fff;
$black: #000;
$hpBlue: #0171ad;
$hpBlueDark: #075782;
$lightGrey: #e8e8e8;

$buttonDisabledColor: #f8f8f8;
$buttonDisabledBG: #ccc;
$buttonDarkBG: #1e2224;
$buttonDarkBGHover: #4B4E4F;

// Fonts
$fontRegular: 'HPSimplified';
$fontLight: 'HPSimplifiedLight';
$fontWolf: 'Wolf';
$fontHelvetia: 'Helvetica-Bold';

$maxWidth: 1280px;

// Breakpoints
$tabletWidth: 768px;
$desktopWidth: 1025px;
.tabs-dark {
  @include desktop {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.tis {
  &__image {
    display: flex;

    @include phone {
      padding-left: 20px;
      padding-right: 20px;
    }

    img {
      width: 100%;
    }
  }

  &__links-wrapper {
    margin-bottom: 10px;

    @include phone {
      padding: 0 25px 25px 25px;
    }

    @include tabletMin {
      padding-bottom: 25px;
    }
  }

  &__links {
    transition: all 500ms;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include tabletMin {
      justify-content: center;
    }

    @include phone {
      flex-direction: column;
    }
  }

  &__link {
    opacity: .5;
    transition: all 500ms;
    white-space: normal;
    text-align: left;
    display: inline-block;
    cursor: pointer;
    flex: 0 0 auto;
    border-top: 2px solid transparent;
    font-size: 20px;
    position: relative;

    @include phone {
      margin-top: 1em;
      max-width: 100%;
    }

    @include tabletMin {
      padding: 10px 25px;
      max-width: 33.3333333%;
    }

    .description {
      font-family: $fontLight;
    }

    &.current-tab {
      opacity: 1;

      &::after {
        position: absolute;
        left: 0;
        top: -10px;
        width: 100%;
        content: '';
        height: 4px;
        background-color: $hpBlue;

        @include tabletMin {
          left: 25px;
          top: -2px;
          width: calc(100% - 50px);
        }
      }
    }

    :first-child {
      &.current-tab {
        &::after {
          left: 0;
        }
      }
    }
  }
}
.headline {
	&--bigpadding {
		padding: 20px;

		@include desktop {
			padding: 80px 70px;
		}

		@include tablet {
			padding: 50px 55px;
		}
	}

	&--smallpadding {
		padding: 20px;

		@include desktop {
			padding: 30px 70px;
		}

		@include tablet {
			padding: 30px 55px;
		}
	}

	&__wrapper {
		text-align: center;
		margin: 0 auto;
	}

	&--dark-bg {
		background-color: $black;
		color: $white;
	}

	&--no-pb {
		padding-bottom: 0;
	}
}

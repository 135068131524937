@font-face {
  font-family: $fontLight;
  src: url(../../public/hp-static/styles/fonts/latin-e-light-eot.eot);
  src: url(../../public/hp-static/styles/fonts/latin-e-light-eot.eot?#iefix) format("embedded-opentype"), url("../../public/hp-static/styles/fonts/latin-e-light-woff.woff") format("woff"), url("../../public/hp-static/styles/fonts/latin-e-light-ttf.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $fontRegular;
  src: url(../../public/hp-static/styles/fonts/latin-e-regular-eot.eot);
  src: url(../../public/hp-static/styles/fonts/latin-e-regular-eot.eot?#iefix) format("embedded-opentype"), url("../../public/hp-static/styles/fonts/latin-e-regular-woff.woff") format("woff"), url("../../public/hp-static/styles/fonts/latin-e-regular-ttf.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $fontWolf;
  src: url(../../public/hp-static/styles/fonts/SharpGroteskBook-25.eot);
  src: url(../../public/hp-static/styles/fonts/SharpGroteskBook-25.eot?#iefix) format("embedded-opentype"), url("../../public/hp-static/styles/fonts/SharpGroteskBook-25.woff") format("woff"), url("../../public/hp-static/styles/fonts/SharpGroteskBook-25.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $fontHelvetia;
  src: url("../../public/hp-static/styles/fonts/Helvetica-Bold.eot?#iefix") format("embedded-opentype"), url("../../public/hp-static/styles/fonts/Helvetica-Bold.woff") format("woff"), url("../../public/hp-static/styles/fonts/Helvetica-Bold.ttf") format("truetype"), url("../../public/hp-static/styles/fonts/Helvetica-Bold.svg#Helvetica-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: $fontLight;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01875rem;
  background-color: white;
}

.font-regular {
  font-family: $fontRegular;
}

.font-helvetica {
  font-family: $fontHelvetia, helvetica !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.font-helvetica-regular {
  font-family: $fontHelvetia, helvetica !important;
  letter-spacing: 0 !important;
}

.font-wolf {
  font-family: $fontWolf !important;
}

.font-mini {
  font-style: italic !important;
}
.image-text {
  margin-top: 70px;
  margin-bottom: 70px;
  display: flex;

  @include phone {
    flex-direction: column;
  }

  &__image {
    max-width: 620px;

    @include tabletMin {
      margin-right: 60px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__text {
    max-width: 460px;

    @include phone {
      margin-top: 30px;
    }

    &-subline {
      color: $hpBlueDark;
      padding-bottom: 50px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 50px;
        height: 2px;
        background-color: $hpBlueDark;
        bottom: 33px;
        left: 0;
      }
    }
  }

  &.image-is-right {
    @include tabletMin {
      .image-text__image {
        order: 2;
        margin-left: 60px;
        margin-right: 0;
      }

      .imge-text__text {
        order: 1;
      }
    }
  }
}
body,
img,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
td,
ul,
ol,
li,
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  box-sizing: border-box;
}

.clearfix {
  zoom: 1;
}

.clearfix::before, .clearfix::after {
  content: "";
  display: table;
  clear: both;
}

::-moz-selection {
  background: #f05332;
  color: white;
  text-shadow: none;
}

::selection {
  background: #f05332;
  color: white;
  text-shadow: none;
}

a {
  color: #0171ad;
  text-decoration: none;
  position: relative;
  transition: all 0.4s ease;
}

a:hover,
a:focus,
a:visited:hover,
a:visited:focus {
  color: $hpBlueDark;
  text-decoration: none;
}

.nav-mobile {
    display: flex;
    position: absolute;
    justify-content: right;
    top: 0;
    width: 100%;
    padding: 20px 10px 20px 10px;
    z-index: 20;

    @include desktop {
        display: none;
    }

    .nav_btn_wrapper {
        padding: 10px;


        &.close {
            margin-top: 10px;

            .navrow {
                width: 30px;
                height: 3px;
                background: white;
                margin-top: 5px;
                border-radius: 5px;

                &:first-of-type {
                    margin-top: -5px;
                    transform: rotate(45deg)
                }

                &:last-of-type {
                    margin-top: -3px;
                    transform: rotate(-45deg)
                }
            }
        }

        .navrow {
            width: 30px;
            height: 3px;
            background: white;
            margin-top: 5px;
            border-radius: 5px;

            &:first-of-type {
                margin-top: -5px;
            }

            &:last-of-type {
                margin-top: 5px;
            }
        }
    }
}

.navigation-mobile {
    display: none;
    position: fixed;
    background: black;
    width: 100%;
    height: 100vh;
    z-index: 25;

    &.active {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-items: center;
    }

    a {
        color: white;
        line-height: 75px;
        font-size: 20px;
    }
}
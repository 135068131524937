.product-list {
  margin-bottom: 20px;

  @include tabletMin {
    margin-bottom: 50px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;

    @include tablet {
      width: 50%;
    }

    @include desktop {
      width: 25%;
    }

    .img-container {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 20px;

      img {
        display: block;
        width: 100%;
      }

      .badge {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 70px;
      }
    }

    h4 {
      margin: 15px 0;
    }

    ul {
      margin-left: 15px;
      text-align: left;
    }

    .cta-container {
      margin-top: auto;
    }
  }
}
.nav-desktop {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    z-index: 200;
    display: none;
    position: absolute;
    justify-items: right;
    gap: 60px;
    padding: 20px;
    padding-left: 110px;

    @include desktop {
        display: flex;
    }

    a {
        color: white;
        font-size: 16px;
        gap: 20px;

        &:hover {
            border-bottom: 1px solid $hpBlue;
            color: $hpBlue;
        }
    }
}
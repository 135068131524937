.partner {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3em;
  &__headline {
    margin-top: 5rem;
  }

  img {
    width: 150px;
    margin: 1em 2em;
  }
}
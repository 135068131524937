@import "variables";
@import "mixins";
@import "animations";
@import "fonts";
@import "base";
@import "colors";
@import "buttons";
@import "typography";
@import "cta";
@import "modal";
@import "section-headline";
@import "section-hero-image";
@import "section-tab-image-switch";
@import "section-image-text";
@import "section-product-click-area";
@import "section-big-content-area";
@import "section-pre-footer-text";
@import "section-footer";
@import "section-feature-grid";
@import "section-product-list";
@import "section-partner";
@import "slick-slider";
@import "section-navigation-mobile";
@import "section-navigation-desktop";
@import "popup";

.max-width {
	max-width: $maxWidth;
	width: 100%;
}

.centered {
	margin-left: auto;
	margin-right: auto;
}

.rounded {
	border-radius: 6px;
}

.section-padding {
	padding: 20px;
}

/**
 * Extra styles
 */

.tab-container {
	ul.sh-tabs_links {
		li {
			font-size: 12px;
		}
	}
}

.text-on-background {
	@include tablet {
		width: 75%;
		padding: 40px 100px;
	}
}

ul.gray-cards {
	@include desktop {
		padding-left: 15%;
	}
	li {
		@include desktop {
			width: 33.33% !important;
		}
	}
}

/**
 * Cookie styles
 */

.CookieConsent {
	position: fixed;
	width: 100%;
	margin: 0 auto;
	bottom: 0;
	z-index: 9999;

	background: $hpBlue;
	color: $white;
	padding: 1em;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	align-self: center;

	@include tablet {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	button {
		margin: 0;
		margin-top: 1em;
		background: $buttonDarkBG !important;

		@include tablet {
			margin-top: 0;
		}
	}
}

/**
 * Hacky responsive styles
 */

.sh-tabs.tabs-dark
	.sh-tabs_links-wrapper.line-on-top.links-content.width-is-l
	ul.sh-tabs_links {
	@include tablet {
		flex-direction: column;
		li {
			width: 100%;
			margin: 1em 0 0;
			padding: 0;
			&::after {
				left: 0;
				top: -10px;
			}
		}
	}
}

.pre-footer-image .dynamic-image {
	@include tablet {
		background-image: url("../../public/hp-static/uploads/images/wolf-bottom-d.jpg") !important;
		background-position: 40%;
	}
}

.sh-tabs.sh-tabs_alternative
	#tab1-second
	.module-sh-303
	.module-sh-303-content {
	@include tablet {
		img {
			display: none;
		}
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 245px;
			background-size: cover;
		}
		&:first-of-type {
			&::before {
				background-image: url("../../public/hp-static/uploads/images/wolf-tab1-half-left-mobile.jpg");
			}
		}
		&:last-of-type {
			&::before {
				background-image: url("../../public/hp-static/uploads/images/wolf-tab1-half-right-mobile.jpg");
			}
		}
	}
}
